import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Query, compose, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import {
  SOLD_CACHE,
  MODAL_CACHE,
  MAP_LOCATION_CACHE,
  AGENT_CACHE,
  BID_LINK_CACHE,
  DESCRIPTION_CACHE,
  NABOLAG_CACHE,
  SMARTBOLIGANALYSE_CACHE,
  GET_PRINTABLE
} from '../../../client/__graphql__/queries';
import { CloseIcon } from './CloseIcon';
import Map from './Statkart';
import { AgentItem } from './AgentItem';
import { Loader } from './Loader';
import { BestillSalgsoppgave } from '../forms/BestillSalgsoppgave';
import { formatCurrency } from '../../utils/formatCurrency';
import { KomplettSalgsoppgaveText } from './KomplettSalgsoppgaveText';

const ModalClose = styled(CloseIcon)``;

const SalesLink = styled.a`
  margin-top: 30px;
  padding: 10px;
  background: #eeeeee;
  display: block;
  font-weight: bold;
  font-size: 16px;
  color: black;
  text-decoration: none;
  text-align: center;
`;

const StyledModalOverlay = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 10000;

  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const StyledModalWrapper = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: ${(props) =>
    (props.type !== 'map' &&
      props.type !== 'neighbourhood' &&
      props.type === 'smartbolig') ||
    (props.type !== 'valuation' && props.type !== 'printable' && props.type !== 'bid')
      ? '20px'
      : 0};
  background: white;
  z-index: 10001;
  overflow-y: ${(props) =>
    props.type === 'neighbourhood' ||
    props.type === 'smartbolig' ||
    props.type === 'valuation' ||
    props.type === 'printable' ||
    props.type === 'bid'
      ? 'hidden'
      : 'scroll'};
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const StyledModalContent = styled.div`
  padding: ${(props) => (props.type === 'map' ? '0' : '40px 0 0 0')};
  width: 100%;
  height: ${(props) =>
    props.type === 'neighbourhood' ||
    props.type === 'smartbolig' ||
    props.type === 'valuation' ||
    props.type === 'printable' ||
    props.type === 'bid'
      ? '100%'
      : 'auto'};
  ${(props) =>
    props.type === 'agent'
      ? 'display: flex; align-items: center; flex-flow: column;'
      : ''}
  font-size: 16px;

  h2 {
    font-size: 16px;
    font-weight: 500;
  }

  .iframe-scroll-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    width: 100%;
    height: 100%;

    iframe {
      padding-top: 20px;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .missing {
    display: block;
    padding: 20px;
    width: 100%;
    font-size: 20px;
    text-align: center;
    color: rgb(150, 150, 150);
  }
`;

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      iframeLoaded: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        ready: true
      });
    }, 100);
  }

  _handleClose() {
    const { client } = this.props;
    const { objectPage } = client.readQuery({
      query: gql`
        query {
          objectPage {
            modal {
              scroll
            }
          }
        }
      `
    });
    client.writeData({
      data: {
        objectPage: {
          __typename: 'ObjectPage',
          infotab: {
            __typename: 'InfoTab',
            show: false,
            sectionOpen: false
          },
          modal: {
            __typename: 'Modal',
            show: false
          }
        }
      }
    });
    if (typeof document !== 'undefined') {
      document.body.classList.remove('no-scroll');
      window.scroll(0, objectPage.modal.scroll);
    }
  }

  _energyLabelColorLabel(color) {
    switch (color) {
      case 'green':
        return 'Grønn';
      case 'lightGreen':
        return 'Lys grønn';
      case 'yellow':
        return 'Gul';
      case 'orange':
        return 'Oransje';
      case 'red':
        return 'Rød';
      default:
        return '';
    }
  }

  _getDsUrl = (data) => {
    if (!data) return null;
    const { url, type } = data;
    if (!url) return null;
    if (type === 'attachment') {
      return (
        <SalesLink
          href={`https://digitalsalgsoppgave.garanti.no/docs?url=${url}`}
          target="_blank"
        >
          Last ned salgsoppgave
        </SalesLink>
      );
    }
    return (
      <SalesLink href={url} target="_blank">
        Last ned salgsoppgave
      </SalesLink>
    );
  };

  _getContent(type, ready) {
    const { sguid, match } = this.props;
    let path = match && match.params && match.params.p2;

    switch (type) {
      case 'sales-text':
        return (
          <Query
            query={DESCRIPTION_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid: sguid, path } }}
          >
            {({ data: { getObjectData } }) => {
              if (getObjectData) {
                return (
                  <StyledText>
                    <KomplettSalgsoppgaveText getObjectData={getObjectData} />
                    {this._getDsUrl(getObjectData.dsUrl)}
                  </StyledText>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      case 'map':
        return (
          <Query
            query={MAP_LOCATION_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid: sguid, path } }}
          >
            {({ data: { getObjectData } }) => {
              if (getObjectData) {
                return (
                  <Fragment>
                    <Map
                      center={{
                        lat: getObjectData.lat,
                        lng: getObjectData.lng
                      }}
                    />
                  </Fragment>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      case 'neighbourhood':
        return (
          <Query
            query={NABOLAG_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid: sguid, path } }}
          >
            {({ data: { getObjectData } }) => {
              if (getObjectData) {
                return (
                  <Fragment>
                    {getObjectData && getObjectData.nabolagprofil ? (
                      <div className="iframe-scroll-wrapper">
                        <iframe
                          title="nabolagsprofil"
                          src={getObjectData && getObjectData.nabolagprofil}
                        />
                      </div>
                    ) : (
                      <FlexWrapper>
                        <h2>Nabolagsprofil ikke funnet</h2>
                      </FlexWrapper>
                    )}
                  </Fragment>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      case 'smartbolig':
        return (
          <Query
            query={SMARTBOLIGANALYSE_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid: sguid, path } }}
          >
            {({ data: { getObjectData } }) => {
              if (getObjectData) {
                return (
                  <Fragment>
                    {getObjectData && getObjectData.smartBoligAnalyse ? (
                      <div className="iframe-scroll-wrapper">
                        <iframe
                          title="smartboliganalyse"
                          src={getObjectData && getObjectData.smartBoligAnalyse}
                        />
                      </div>
                    ) : (
                      <FlexWrapper>
                        <h2>Smart Boliganalyse ikke funnet</h2>
                      </FlexWrapper>
                    )}
                  </Fragment>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      case 'agent':
        return (
          <Query
            query={AGENT_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid: sguid, path } }}
          >
            {({ data: { getObjectData } }) => {
              if (getObjectData) {
                return (
                  <Fragment>
                    {getObjectData.agents.length
                      ? getObjectData.agents.map((item, index) => (
                          <AgentItem
                            mobile="true"
                            key={index}
                            name={item.name}
                            title={item.title}
                            phone={item.phone}
                            email={item.email}
                            src={item.image}
                            office={item.office}
                            isWhiteLabel={getObjectData.isWhitelabel}
                          />
                        ))
                      : null}
                  </Fragment>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      case 'bid':
        return (
          <Query
            query={BID_LINK_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid: sguid, path } }}
          >
            {({ data, data: { getObjectData } }) => {
              if (getObjectData) {
                return (
                  <Fragment>
                    <div className="iframe-scroll-wrapper">
                      <iframe title="budgiving" src={getObjectData.bidLink} />
                    </div>
                  </Fragment>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      case 'valuation':
        return (
          <Fragment>
            <div className="iframe-scroll-wrapper">
              <iframe title="verdivurdering" src="https://salg.garanti.no" />
            </div>
          </Fragment>
        );
        break;
      case 'printable':
        return (
          <Query
            query={SOLD_CACHE}
            fetchPolicy="cache-only"
            variables={{ input: { sguid: sguid, path } }}
          >
            {({ data, data: { getObjectData } }) => {
              if (getObjectData) {
                return (
                  <BestillSalgsoppgave
                    sguid={sguid}
                    sold={getObjectData && getObjectData.sold}
                  />
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      default:
        return null;
        break;
    }
  }

  render() {
    return (
      <Query query={MODAL_CACHE}>
        {({ data: { objectPage } }) => {
          if (objectPage && objectPage.modal) {
            return (
              <Fragment>
                <StyledModalWrapper
                  type={objectPage.modal.type}
                  show={objectPage.modal.show}
                >
                  <StyledModalContent type={objectPage.modal.type}>
                    {this._getContent(
                      objectPage.modal && objectPage.modal.type,
                      this.state.ready
                    )}
                  </StyledModalContent>
                  <ModalClose
                    type={objectPage.modal.type}
                    onClick={() => this._handleClose()}
                  />
                </StyledModalWrapper>
                <StyledModalOverlay show={objectPage.modal.show} />
              </Fragment>
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

const FlexWrapper = styled.div`
  width: 100%;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.div`
  padding-bottom: 3rem;
  h2 {
    padding-top: 20px;

    &:first-child {
      padding-top: 0;
    }
  }
`;

export default compose(withApollo)(Modal);
