import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Spring, animated, interpolate } from 'react-spring/renderprops.cjs';
import { Query, compose, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { GET_OBJECT_DATA, AGENT_CACHE } from '../../../client/__graphql__/queries';
import { CloseIcon } from './CloseIcon';
import { InfoItem } from './InfoItem';
import { InfoLink } from './InfoLink';
import { AgentItemTab } from './AgentItemTab';
import MoreDetailsBtn from './MoreDetailsBtn';
import { formatCurrency } from '../../utils/formatCurrency';
import Tabs from './Tabs';
import { Sold } from './Sold';
import { KomplettSalgsoppgaveText } from './KomplettSalgsoppgaveText';
import { getSizeData } from '../utils/getSizeData';

const InfoRow = styled.div`
  display: flex;
  width: 100%;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-flow: column;
  width: calc((100% / 3) - 30px);
  margin-right: 30px;

  @media screen and (max-width: 1200px) {
    margin-right: 10px;
  }
`;

const InfoColumnRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  div {
    width: calc(50% - 15px);
    margin-bottom: 0 !important;
  }
`;

const SideTabOuterWrapper = styled(animated.div)`
  position: fixed;
  right: 0;
  z-index: 1000;
  background: #eeeeee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const SideTab = styled.div`
  overflow-y: ${(props) => (props.open ? 'hidden' : 'scroll')};
  overflow-x: hidden;
  width: ${(props) => (props.open ? '100%' : 'calc(100% + 1rem)')};
  height: 100%;
`;

const SideTabTop = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 1000;
`;

const SideTabBottom = styled.div`
  transition: background 0.2s ease-in-out;
  position: relative;
  width: 100%;
  float: left;
  color: rgb(50, 50, 50);
`;

const SideTabInfo = styled.div`
  display: flex;
  flex-flow: column;
  flex: 0 0 450px;
  width: 450px;
  height: 100%;
  padding: ${(props) => (props.additionalContent ? '30px 38px' : '30px 38px 0px 38px;')};
  float: left;

  @media screen and (max-width: 1100px) {
    width: 330px;
    flex: 0 0 330px;
  }

  h2 {
    font-size: 2em;
    font-weight: 400;
    margin: 0;
  }
  h3 {
    margin-top: 0;
    font-size: 20px;
    font-weight: 300;
  }

  p {
    font-weight: 300;
    max-width: 400px;
    margin-top: 0;
  }
`;
const SideTabLinks = styled.div`
  position: relative;
  display: flex;
  padding: 0 30px;
  width: 100%;
  height: 100%;
  float: left;
`;

const SideTabGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1em;
  width: 100%;

  &.expand {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

const SideBottomLinks = styled.div`
  padding: 0 38px 30px 38px;
  width: 400px;
  float: left;
`;

const SideBottomMoreInfo = styled.div`
  width: 450px;
  height: 100%;
  background: #f6f6f6;
  float: left;
  overflow: hidden;
`;

const SideBottomText = styled.div`
  width: calc(100% - 450px);
  height: 100%;
  overflow: hidden;
  background: white;
  float: left;

  h2 {
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;

    &:first-child {
      padding-top: 0;
    }
  }
`;

const SideBottomInsideText = styled.div`
  width: 100%;
  height: 100%;
  padding: 22px 30px 4rem 30px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const AdditionalInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 38px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const AdditionalInfoItem = styled.div`
  width: 50%;
  margin-bottom: 15px;
  float: left;
  font-size: 14px;
  padding: 2px;

  b {
    display: block;
    font-size: 14px;
  }

  a {
    color: black;
  }
`;

const SalesLinkWrapper = styled.div`
  display: block;
  width: 100%;
`;

const SalesLink = styled.a`
  margin-top: 30px;
  padding: 10px;
  background: #eeeeee;
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  color: black;
  text-decoration: none;
`;

class InfoTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      width: 0,
      height: 0,
      bottomHeight: 900,
      topHeight: 0,
      agents: false,
      init: true
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight - 150
      });
      this.resize = window.addEventListener('resize', this._handleResize.bind(this));
    }
    setTimeout(() => {
      this.setState({
        init: false
      });
    }, 1000);

    this.setState({
      topHeight: this.top && this.top.clientHeight ? this.top.clientHeight : 0
    });
  }

  componentDidUpdate(prevProps) {
    const { client } = this.props;
    if (prevProps.showAdditionalContent !== this.props.showAdditionalContent) {
      if (this.props.showAdditionalContent === true) {
        if (this.outer && this.outer.scrollTop > 0) {
          this.outer.scrollTo(0, 0);
        }

        const { view } = client.readQuery({
          query: gql`
            query {
              view {
                size {
                  width
                  height
                }
              }
            }
          `
        });

        this.setState({
          width: view.size.width,
          height: view.size.height - 98
        });
      } else {
        const { view } = client.readQuery({
          query: gql`
            query {
              view {
                size {
                  width
                  height
                }
              }
            }
          `
        });

        this.setState({
          width: view.size.width,
          height: view.size.height - 150
        });
      }
    }
  }

  _handleResize() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight - 150
    });
  }

  componentWillUnmount() {
    if (this.resize) {
      this.resize.removeEventListener();
    }
  }

  _handleCloseTab() {
    const { client } = this.props;
    if (typeof window !== 'undefined') {
      this.setState(
        {
          width: window.innerWidth,
          height: window.innerHeight - 150,
          open: false
        },
        () => {
          client.writeData({
            data: {
              objectPage: {
                __typename: 'ObjectPage',
                externalLink: false,
                infotab: {
                  __typename: 'InfoTab',
                  show: false,
                  sectionOpen: false,
                  currentSection: ''
                },
                modal: {
                  __typename: 'Modal',
                  show: false,
                  type: ''
                }
              }
            }
          });
        }
      );
    }
  }

  _toggleGallery() {
    const { client } = this.props;
    const { objectPage } = client.readQuery({
      query: gql`
        query {
          common {
            menu {
              show
            }
          }
          objectPage {
            gallery {
              show
            }
          }
        }
      `
    });

    client.writeData({
      data: {
        common: {
          __typename: 'Common',
          menu: {
            __typename: 'Menu',
            show: false
          }
        },
        objectPage: {
          __typename: 'ObjectPage',
          gallery: {
            __typename: 'Gallery',
            show: !objectPage.gallery.show
          },
          infotab: {
            __typename: 'InfoTab',
            show: false,
            sectionOpen: false
          }
        }
      }
    });
  }

  _handleOpenTabFromClosed(tab) {
    const { client } = this.props;
    let clientData = {
      objectPage: {
        __typename: 'ObjectPage',
        infotab: {
          __typename: 'InfoTab',
          show: true
        }
      }
    };
    clientData.objectPage.infotab.sectionOpen = true;
    clientData.objectPage.infotab.currentSection = tab;
    client.writeData({
      data: clientData
    });
  }

  _handleOpenTab() {
    const { client } = this.props;
    client.writeData({
      data: {
        objectPage: {
          __typename: 'ObjectPage',
          externalLink: false,
          infotab: {
            __typename: 'InfoTab',
            show: true
          }
        }
      }
    });
  }

  _handleScrollInsideTop() {
    if (this.outer.scrollTop > 0) {
      this.outer.scrollTo(0, 0);
    }
  }

  _energyLabelColorLabel(color) {
    switch (color) {
      case 'green':
        return 'Grønn';
      case 'lightGreen':
        return 'Lys grønn';
      case 'yellow':
        return 'Gul';
      case 'orange':
        return 'Oransje';
      case 'red':
        return 'Rød';
      default:
        return '';
    }
  }

  _getPriceType(data) {
    if (data && data.ownAssignmentType === 'Utleie bolig') {
      let label = '';
      let price = 0;
      if (data.rentPerMonth) {
        label = 'Månedsleie';
        price = data.rentPerMonth;
      }
      if (data.rentPerWeek) {
        label = 'Ukesleie';
        price = data.rentPerWeek;
      }
      if (data.rentPerYear) {
        label = 'Årsleie';
        price = data.rentPerYear;
      }
      if (data.rentPerDay) {
        label = 'Dagsleie';
        price = data.rentPerDay;
      }

      return <InfoItem label={label} value={price ? `${formatCurrency(price)},-` : 0} />;
    } else {
      return (
        <InfoItem
          label="Prisantydning"
          value={
            data && data.price && data.price.value ? (
              `${data.price.value ? formatCurrency(data.price.value) : 0},-`
            ) : data &&
              data.price &&
              data.price.from === null &&
              data.price.to === null &&
              data.price.value === null ? (
              <div>-</div>
            ) : (
              <Fragment>
                <div>
                  fra{' '}
                  {data && data.price && data.price.from
                    ? formatCurrency(data.price.from)
                    : 0}
                  ,-{' '}
                </div>
                <div>
                  til{' '}
                  {data && data.price && data.price.to
                    ? formatCurrency(data.price.to)
                    : 0}
                  ,-{' '}
                </div>
              </Fragment>
            )
          }
        />
      );
    }
  }

  _getSizeMain = (data) => {
    if (!data) return null;
    const size = getSizeData(data);
    if (!size) return null;
    const { type, from, to } = size;
    if (!type || !from) return null;
    const title = type === 'usableInternal' ? 'BRA-I' : 'Primærrom';
    if (to) {
      return (
        <AdditionalInfoItem>
          <b>{title}:</b> {from}m<sup>2</sup> - {to}m<sup>2</sup>
        </AdditionalInfoItem>
      );
    }
    return (
      <AdditionalInfoItem>
        <b>{title}:</b> {from}m<sup>2</sup>
      </AdditionalInfoItem>
    );
  };

  _getSizeTop = (data) => {
    if (!data) return null;
    const size = getSizeData(data);
    if (!size) return null;
    const { type, from, to } = size;
    if (!type || !from) return null;
    const title = type === 'usableInternal' ? 'BRA-I' : 'P-rom';
    if (to) {
      return (
        <InfoItem label={title}>
          <div>
            fra {from}m<sup>2</sup>
          </div>
          <div>
            til {to}m<sup>2</sup>
          </div>
        </InfoItem>
      );
    }
    return (
      <InfoItem label={title}>
        {from}m<sup>2</sup>
      </InfoItem>
    );
  };

  _getDsUrl = (data) => {
    if (!data) return null;
    const { url, type } = data;
    if (!url) return null;
    if (type === 'attachment') {
      return (
        <SalesLink
          href={`https://digitalsalgsoppgave.garanti.no/docs?url=${url}`}
          target="_blank"
        >
          Last ned salgsoppgave
        </SalesLink>
      );
    }
    return (
      <SalesLink href={url} target="_blank">
        Last ned salgsoppgave
      </SalesLink>
    );
  };

  render() {
    let { show, externalLink, client, sguid, showAdditionalContent, galleryShow } =
      this.props;
    return (
      <Query
        query={GET_OBJECT_DATA}
        fetchPolicy="cache-only"
        variables={{ input: { sguid, path: this.props.match.params.p2 } }}
      >
        {({ data: { getObjectData }, loading }) => {
          if (getObjectData) {
            return (
              <Spring
                immediate={externalLink || galleryShow}
                from={{
                  x: 0,
                  t: 'translate3d(0px, 0px, 0px)',
                  o: 1,
                  width: 450,
                  height: this.state.height,
                  bottom: 0
                }}
                to={{
                  x: showAdditionalContent ? 1 : 0,
                  t: show ? 'translate3d(100px, 0px, 0px)' : 'translate3d(0px, 0px, 0px)',
                  o: show ? 0 : 1,
                  width: showAdditionalContent ? this.state.width : 450,
                  height: showAdditionalContent ? this.state.height : this.state.height,
                  bottom: 0
                }}
                onRest={(props) => {
                  if (props && props.x === 1) {
                    this.setState({
                      bottomHeight: this.outer.clientHeight - this.top.clientHeight
                    });
                  }
                }}
              >
                {({ x, t, o, width, height, bottom }) => {
                  return (
                    <SideTabOuterWrapper
                      style={{
                        width: width,
                        height: height,
                        bottom: bottom,
                        transform: t,
                        opacity: o
                      }}
                    >
                      <SideTab open={x === 1} ref={(elem) => (this.outer = elem)}>
                        <SideTabTop
                          ref={(elem) => {
                            this.top = elem;
                          }}
                          additionalContent={x === 1}
                        >
                          <SideTabInfo additionalContent={x === 1}>
                            <h2>{getObjectData.street}</h2>
                            <h3>
                              {getObjectData.zip} {getObjectData.city}
                            </h3>
                            {getObjectData &&
                            getObjectData.ownAssignmentType &&
                            getObjectData.ownAssignmentType === 'Utleie bolig' ? (
                              getObjectData && getObjectData.sold ? (
                                <Sold mobile="true">Utleid</Sold>
                              ) : null
                            ) : getObjectData && getObjectData.sold ? (
                              <Sold mobile="true">Solgt</Sold>
                            ) : null}
                            <InfoRow>
                              {this._getPriceType(getObjectData)}
                              {this._getSizeTop(getObjectData)}
                              <InfoItem label="Type" value={getObjectData.type} />
                            </InfoRow>
                            <Spring
                              immediate={externalLink}
                              from={{ opacity: 0 }}
                              to={{ opacity: x === 1 ? 1 : 0 }}
                            >
                              {({ opacity }) => (
                                <CloseIcon
                                  style={{ opacity: opacity }}
                                  onClick={() => this._handleCloseTab()}
                                />
                              )}
                            </Spring>
                          </SideTabInfo>
                          <Spring
                            immediate={externalLink}
                            from={{ y: 0 }}
                            to={{ y: x === 1 ? 1 : 0 }}
                          >
                            {({ y }) => (
                              <SideTabLinks
                                style={{
                                  display: x === 1 ? 'flex' : 'none',
                                  opacity: y
                                }}
                              >
                                <SideTabGrid
                                  className={
                                    getObjectData && getObjectData.smartBoligAnalyse
                                      ? 'expand'
                                      : ''
                                  }
                                >
                                  <InfoLink
                                    value="Verdivurdering"
                                    onClick={() => {
                                      window.open(
                                        getObjectData &&
                                          getObjectData.valuation &&
                                          getObjectData.valuation.length
                                          ? getObjectData.valuation
                                          : 'https://selge.garanti.no',
                                        '_blank'
                                      );
                                    }}
                                  />
                                  {getObjectData && getObjectData.smartBoligAnalyse ? (
                                    <InfoLink
                                      value="Smart Boliganalyse"
                                      onClick={() => {
                                        client.writeData({
                                          data: {
                                            objectPage: {
                                              __typename: 'ObjectPage',
                                              infotab: {
                                                __typename: 'InfoTab',
                                                show: true,
                                                sectionOpen: true,
                                                currentSection: 'smartbolig'
                                              }
                                            }
                                          }
                                        });
                                      }}
                                    />
                                  ) : null}
                                  <InfoLink
                                    value="Nabolagsprofil"
                                    onClick={() => {
                                      client.writeData({
                                        data: {
                                          objectPage: {
                                            __typename: 'ObjectPage',
                                            infotab: {
                                              __typename: 'InfoTab',
                                              show: true,
                                              sectionOpen: true,
                                              currentSection: 'neighbourhood'
                                            }
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InfoLink
                                    value="Kart"
                                    onClick={() => {
                                      client.writeData({
                                        data: {
                                          objectPage: {
                                            __typename: 'ObjectPage',
                                            infotab: {
                                              __typename: 'InfoTab',
                                              show: true,
                                              sectionOpen: true,
                                              currentSection: 'map'
                                            }
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  <InfoLink
                                    value="Galleri"
                                    onClick={() => {
                                      this._toggleGallery();
                                    }}
                                  />
                                  <InfoLink
                                    value="Digital salgsoppgave"
                                    onClick={() => {
                                      if (
                                        typeof window !== 'undefined' &&
                                        getObjectData.estateId
                                      ) {
                                        window.location.href = `https://digitalsalgsoppgave.garanti.no/bestillsalgsoppgave/${getObjectData.estateId}`;
                                      }
                                    }}
                                  />
                                  <InfoLink
                                    value="Visningspåmelding"
                                    onClick={() => {
                                      if (
                                        typeof window !== 'undefined' &&
                                        getObjectData.estateId
                                      ) {
                                        if (typeof window !== 'undefined') {
                                          window.open(
                                            `https://meglervisning.no/registrer/msgar/${getObjectData.estateId}`
                                          );
                                        }
                                      }
                                    }}
                                  />

                                  <InfoLink
                                    value="Kontakt megler"
                                    onClick={() => {
                                      client.writeData({
                                        data: {
                                          objectPage: {
                                            __typename: 'ObjectPage',
                                            infotab: {
                                              __typename: 'InfoTab',
                                              show: true,
                                              sectionOpen: true,
                                              currentSection: 'agent'
                                            }
                                          }
                                        }
                                      });
                                    }}
                                  />
                                  {getObjectData.bidLink && !getObjectData.sold ? (
                                    <InfoLink
                                      value="Gi bud"
                                      onClick={() => {
                                        if (typeof window !== 'undefined') {
                                          window.open(getObjectData.bidLink, '_blank');
                                        }
                                      }}
                                    />
                                  ) : (
                                    <InfoLink value="Gi bud" hidden={true} />
                                  )}
                                </SideTabGrid>
                              </SideTabLinks>
                            )}
                          </Spring>
                        </SideTabTop>
                        <Spring
                          immediate={externalLink}
                          from={{ z: 0, y: 0 }}
                          to={{ z: x === 0 ? 1 : 0, y: x === 1 ? 1 : 0 }}
                        >
                          {({ z, y }) => (
                            <SideTabBottom
                              moreInfo={
                                getObjectData &&
                                getObjectData.price &&
                                getObjectData.price.from
                                  ? true
                                  : false
                              }
                              style={{
                                background: '#eeeeee'
                              }}
                              ref={(elem) => (this.bottom = elem)}
                            >
                              <Tabs
                                match={this.props.match}
                                sguid={sguid}
                                show={x === 1}
                                height={this.state.bottomHeight}
                                showTab={
                                  this.props.cache && this.props.cache.infotab.sectionOpen
                                }
                                pane={
                                  this.props.cache &&
                                  this.props.cache.infotab.currentSection
                                }
                              />
                              <SideBottomLinks
                                style={{
                                  display: x === 0 ? 'block' : 'none',
                                  opacity: z
                                }}
                              >
                                <p>{getObjectData.title}</p>
                                <MoreDetailsBtn
                                  onClick={() => {
                                    this._handleScrollInsideTop();
                                    this._handleOpenTab();
                                  }}
                                >
                                  Flere detaljer
                                </MoreDetailsBtn>
                                <InfoLink
                                  value="Digital salgsoppgave"
                                  onClick={() => {
                                    if (
                                      typeof window !== 'undefined' &&
                                      getObjectData.estateId
                                    ) {
                                      window.location.href = `https://digitalsalgsoppgave.garanti.no/bestillsalgsoppgave/${getObjectData.estateId}`;
                                    }
                                  }}
                                />
                                <InfoLink
                                  value="Visningspåmelding"
                                  onClick={() => {
                                    if (
                                      typeof window !== 'undefined' &&
                                      getObjectData.estateId
                                    ) {
                                      window.open(
                                        `https://meglervisning.no/registrer/msgar/${getObjectData.estateId}`,
                                        '_blank'
                                      );
                                    }
                                  }}
                                />
                                <InfoLink
                                  value="Verdivurdering"
                                  onClick={() => {
                                    console.log({
                                      link: getObjectData.valuation,
                                      loc: 'tab2'
                                    });
                                    window.open(
                                      getObjectData &&
                                        getObjectData.valuation &&
                                        getObjectData.valuation.length
                                        ? getObjectData.valuation
                                        : 'https://selge.garanti.no',
                                      '_blank'
                                    );
                                    /*if (
                                      typeof window !== 'undefined' &&
                                      getObjectData &&
                                      getObjectData.isWhitelabel === false
                                    ) {
                                      client.writeData({
                                        data: {
                                          objectPage: {
                                            __typename: 'ObjectPage',
                                            infotab: {
                                              __typename: 'InfoTab',
                                              show: true,
                                              sectionOpen: true,
                                              currentSection: 'valuation',
                                            },
                                          },
                                        },
                                      });
                                    } else if (
                                      typeof window !== 'undefined' &&
                                      getObjectData &&
                                      getObjectData.isWhitelabel === true
                                    ) {
                                      window.open(
                                        getObjectData.whitelabelOfficeUrl,
                                        '_blank'
                                      );
                                    }*/
                                  }}
                                />
                                {getObjectData && getObjectData.smartBoligAnalyse ? (
                                  <InfoLink
                                    value="Smart Boliganalyse"
                                    onClick={() =>
                                      this._handleOpenTabFromClosed('smartbolig')
                                    }
                                  />
                                ) : null}
                                <InfoLink
                                  value="Kart"
                                  onClick={() => {
                                    this._handleOpenTabFromClosed('map');
                                  }}
                                />
                                <InfoLink
                                  value="Galleri"
                                  onClick={() => this._toggleGallery()}
                                />
                                <InfoLink
                                  value="Nabolagsprofil"
                                  onClick={() =>
                                    this._handleOpenTabFromClosed('neighbourhood')
                                  }
                                />
                                <InfoLink
                                  value="Kontakt megler"
                                  onClick={() => this._handleOpenTabFromClosed('agent')}
                                />
                                {getObjectData.bidLink && !getObjectData.sold ? (
                                  <InfoLink
                                    value="Gi bud"
                                    onClick={() => {
                                      if (typeof window !== 'undefined') {
                                        window.open(getObjectData.bidLink, '_blank');
                                      }
                                    }}
                                  />
                                ) : null}
                                <InfoLink
                                  value="Personvern"
                                  onClick={() => {
                                    if (
                                      typeof window !== 'undefined' &&
                                      getObjectData &&
                                      getObjectData.isWhitelabel === false
                                    ) {
                                      window.location.href =
                                        'https://garanti.no/personvern';
                                    } else if (
                                      typeof window !== 'undefined' &&
                                      getObjectData &&
                                      getObjectData.isWhitelabel === true
                                    ) {
                                      window.location.href =
                                        getObjectData.whitelabelOfficeUrl;
                                    }
                                  }}
                                />
                              </SideBottomLinks>
                              <SideBottomMoreInfo
                                style={{
                                  height: this.state.bottomHeight,
                                  display: x === 1 ? 'block' : 'none',
                                  opacity: y
                                }}
                              >
                                <AdditionalInfoWrapper>
                                  {getObjectData.purchaseCostsAmount ? (
                                    <AdditionalInfoItem>
                                      <b>Omkostninger:</b>
                                      {getObjectData.purchaseCostsAmount
                                        ? formatCurrency(
                                            getObjectData.purchaseCostsAmount
                                          )
                                        : 0}
                                      ,-
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.totalPrice ? (
                                    <AdditionalInfoItem>
                                      <b>Totalpris inkl. omk:</b>
                                      {getObjectData.totalPrice
                                        ? formatCurrency(getObjectData.totalPrice)
                                        : 0}
                                      ,-
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.ligningsverdi ? (
                                    <AdditionalInfoItem>
                                      <b>Ligningsverdi:</b>{' '}
                                      <span>
                                        {getObjectData && getObjectData.ligningsverdi
                                          ? formatCurrency(getObjectData.ligningsverdi)
                                          : null}
                                        ,- pr. år {getObjectData.ligningsverdiYear}.
                                      </span>
                                    </AdditionalInfoItem>
                                  ) : null}

                                  {getObjectData.ligningsverdi ? (
                                    <AdditionalInfoItem>
                                      <b>Formuesverdi:</b>{' '}
                                      {getObjectData &&
                                      getObjectData.ligningsverdiSekundaer ? (
                                        <span>
                                          {getObjectData &&
                                          getObjectData.ligningsverdiSekundaer > 0
                                            ? formatCurrency(
                                                getObjectData.ligningsverdiSekundaer
                                              )
                                            : null}
                                          ,- i sekundær formuesverdi.
                                        </span>
                                      ) : null}
                                    </AdditionalInfoItem>
                                  ) : null}

                                  {getObjectData.communityTaxes ? (
                                    <AdditionalInfoItem>
                                      <b>Kommunale avgifter:</b>{' '}
                                      {getObjectData.communityTaxes
                                        ? formatCurrency(getObjectData.communityTaxes)
                                        : 0}
                                      ,-
                                    </AdditionalInfoItem>
                                  ) : null}

                                  {getObjectData.estimatedValue ? (
                                    <AdditionalInfoItem>
                                      <b>Verditakst:</b>{' '}
                                      {getObjectData.estimatedValue
                                        ? formatCurrency(getObjectData.estimatedValue)
                                        : 0}
                                      ,-
                                    </AdditionalInfoItem>
                                  ) : null}

                                  {getObjectData.loanFare ? (
                                    <AdditionalInfoItem>
                                      <b>Lånetakst:</b>{' '}
                                      {getObjectData.loanFare
                                        ? formatCurrency(getObjectData.loanFare)
                                        : 0}
                                      ,-
                                    </AdditionalInfoItem>
                                  ) : null}

                                  {getObjectData.monthCosts ? (
                                    <AdditionalInfoItem>
                                      <b>Felleskostnader:</b>{' '}
                                      {getObjectData.monthCosts
                                        ? formatCurrency(getObjectData.monthCosts)
                                        : 0}
                                      ,- pr. mnd
                                    </AdditionalInfoItem>
                                  ) : null}

                                  {getObjectData && getObjectData.commonDebt ? (
                                    <AdditionalInfoItem>
                                      <b>Andel fellesgjeld</b>{' '}
                                      <span>
                                        {formatCurrency(getObjectData.commonDebt)}
                                        ,-
                                      </span>
                                    </AdditionalInfoItem>
                                  ) : null}

                                  {getObjectData && getObjectData.collectiveAssets ? (
                                    <AdditionalInfoItem>
                                      <b>Andel felles formue</b>{' '}
                                      <span>
                                        {formatCurrency(getObjectData.collectiveAssets)}
                                        ,-
                                      </span>
                                    </AdditionalInfoItem>
                                  ) : null}

                                  {getObjectData && getObjectData.propertyTaxAmount ? (
                                    <AdditionalInfoItem>
                                      <b>Eiendomsskatt</b>{' '}
                                      <span>
                                        {formatCurrency(getObjectData.propertyTaxAmount)}
                                        ,-
                                        {getObjectData.propertyTaxYear
                                          ? ` for ${getObjectData.propertyTaxYear}`
                                          : ''}
                                      </span>
                                    </AdditionalInfoItem>
                                  ) : null}

                                  <AdditionalInfoItem>
                                    <b>Eierform:</b> {getObjectData.ownership}
                                    <div />
                                  </AdditionalInfoItem>

                                  {getObjectData && getObjectData.ownPart ? (
                                    <AdditionalInfoItem>
                                      <b>Eierbrøk:</b> {getObjectData.ownPart}
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {this._getSizeMain(getObjectData)}
                                  {getObjectData &&
                                  getObjectData.size &&
                                  getObjectData.size.usableExternalArea ? (
                                    <AdditionalInfoItem>
                                      <b>BRA-E:</b>{' '}
                                      {getObjectData.size.usableExternalArea}m<sup>2</sup>
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData &&
                                  getObjectData.size &&
                                  getObjectData.size.enclosedBalconyArea ? (
                                    <AdditionalInfoItem>
                                      <b>BRA-B:</b>
                                      {getObjectData.size.enclosedBalconyArea}m
                                      <sup>2</sup>
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.bra ? (
                                    <AdditionalInfoItem>
                                      <b>Bruksareal:</b> {getObjectData.bra}m<sup>2</sup>
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.bta ? (
                                    <AdditionalInfoItem>
                                      <b>Bruttoareal:</b> {getObjectData.bta}m<sup>2</sup>
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData &&
                                  getObjectData.size &&
                                  getObjectData.size.openArea ? (
                                    <AdditionalInfoItem>
                                      <b>TBA:</b>
                                      {getObjectData.size.openArea}m<sup>2</sup>
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.propertyArea ? (
                                    <AdditionalInfoItem>
                                      <b>Tomt:</b> {getObjectData.propertyArea}m
                                      <sup>2</sup>{' '}
                                      {getObjectData.plotOwned ? '(eiet)' : '(festet)'}
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.level ? (
                                    <AdditionalInfoItem>
                                      <b>Etasje:</b> {getObjectData.level}.
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.rooms ? (
                                    <AdditionalInfoItem>
                                      <b>Rom (soverom):</b> {getObjectData.rooms} (
                                      {getObjectData.bedrooms || 0})
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.constructionYear ? (
                                    <AdditionalInfoItem>
                                      <b>Byggeår:</b> {getObjectData.constructionYear}
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData &&
                                  getObjectData.matrikkel &&
                                  getObjectData.matrikkel.gnr ? (
                                    <AdditionalInfoItem>
                                      <b>Gårdsnummer:</b>
                                      {getObjectData.matrikkel.gnr}
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData &&
                                  getObjectData.matrikkel &&
                                  getObjectData.matrikkel.bnr ? (
                                    <AdditionalInfoItem>
                                      <b>Bruksnummer:</b>
                                      {getObjectData.matrikkel.bnr}
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.dyrehold ? (
                                    <AdditionalInfoItem>
                                      <b>Dyrehold:</b>
                                      {getObjectData.dyrehold}
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.energyLabel ? (
                                    <AdditionalInfoItem>
                                      <b>Energimerking:</b>
                                      {getObjectData && getObjectData.energyLabelColor
                                        ? this._energyLabelColorLabel(
                                            getObjectData.energyLabelColor
                                          )
                                        : null}{' '}
                                      ({getObjectData.energyLabel})
                                    </AdditionalInfoItem>
                                  ) : null}
                                  {getObjectData.finncode ? (
                                    <AdditionalInfoItem>
                                      <b>Finnkode:</b>{' '}
                                      <a
                                        href={`https://finn.no/${getObjectData.finncode}`}
                                      >
                                        {getObjectData.finncode}
                                      </a>
                                      <div />
                                    </AdditionalInfoItem>
                                  ) : null}
                                </AdditionalInfoWrapper>
                              </SideBottomMoreInfo>
                              <SideBottomText
                                style={{
                                  height: this.state.bottomHeight,
                                  display: x === 1 ? 'block' : 'none',
                                  opacity: y
                                }}
                              >
                                <SideBottomInsideText>
                                  <KomplettSalgsoppgaveText
                                    getObjectData={getObjectData}
                                  />
                                  {this._getDsUrl(getObjectData.dsUrl)}
                                </SideBottomInsideText>
                              </SideBottomText>
                            </SideTabBottom>
                          )}
                        </Spring>
                      </SideTab>
                    </SideTabOuterWrapper>
                  );
                }}
              </Spring>
            );
          } else if (loading) {
            return null;
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default compose(withApollo)(InfoTab);
